import * as React from 'react';

import './404.scss';

function ErrorPage() {
  return (
    <>
      <section id="error-container">
        <span>404 ERROR</span>
        <h2>
          <strong>We can’t find the page</strong> <br /> you are looking for.
        </h2>
        <p>
          <a className="primary-cta-button" href="/">
            BACK TO HOME
          </a>
        </p>
        <div className="error-img-left">
          <img
            src="https://res.cloudinary.com/hebu10lmu/images/v1612791253/www/blog/error-img-left/error-img-left.webp"
            alt="Error left"
          />
        </div>
        <div className="error-img-right">
          <img
            src="https://res.cloudinary.com/hebu10lmu/images/v1612791256/www/blog/error-img-right/error-img-right.webp"
            alt="Error right"
          />
        </div>
      </section>
    </>
  );
}

export default ErrorPage;
